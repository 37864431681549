
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AudioPreview from "@/components/common/media/preview/AudioPreview.vue";
import DocumentPreview from "@/components/common/media/preview/DocumentPreview.vue";
import ImagePreview from "@/components/common/media/preview/ImagePreview.vue";
import VideoPreview from "@/components/common/media/preview/VideoPreview.vue";
import { FactorOptions } from "@/graphql/API";

@Component({
    name: "FilePreview",
    components: {
        AudioPreview,
        DocumentPreview,
        ImagePreview,
        VideoPreview,
    },
})
export default class FilePreview extends Vue {
    @Prop()
    path!: string;

    @Prop()
    name!: string;

    @Prop()
    fileType!: string;

    @Prop({ type: Boolean, default: false })
    isFactorTable!: boolean;

    @Prop({ type: String, default: "140px" })
    maxWidth!: string;

    @Prop({ type: String, default: "140px" })
    maxHeight!: string;

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    get showFile(): boolean {
        if (this.path && this.fileType && this.previewComponent) {
            return true;
        } else {
            return false;
        }
    }

    get previewComponent(): string | null {
        if (this.fileType) {
            if (this.fileType == "image") {
                return "ImagePreview";
            } else if (this.fileType == "video") {
                return "VideoPreview";
            } else if (this.fileType == "audio") {
                return "AudioPreview";
            } else if (this.fileType == "document") {
                return "DocumentPreview";
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}
