
import { Component, Watch, Vue, PropSync } from "vue-property-decorator";
import { columnOptions } from "@/Options";
import { Pagination } from "@/graphql/API";

@Component({
    components: {},
})
export default class CommentSettings extends Vue {
    @PropSync("mainWidth", Number)
    syncedMainWidth!: number;

    @PropSync("dataWidth", Number)
    syncedDataWidth!: number;

    @PropSync("paddingX", Number)
    syncedPaddingX!: number;

    @PropSync("paddingY", Number)
    syncedPaddingY!: number;

    @PropSync("borderX", Boolean)
    syncedBorderX!: boolean;

    @PropSync("borderY", Boolean)
    syncedBorderY!: boolean;

    @PropSync("fullColumn", Boolean)
    syncedFullColumn!: boolean;

    @PropSync("pagination")
    syncedPagination!: Pagination;

    @PropSync("showTotal")
    syncedShowTotal!: boolean;

    private readonly columnWidths = columnOptions;

    private paddingOptions = [
        {
            label: "0",
            value: 0,
        },
        {
            label: "1",
            value: 1,
        },
        {
            label: "2",
            value: 2,
        },
        {
            label: "3",
            value: 3,
        },
        {
            label: "4",
            value: 4,
        },
        {
            label: "5",
            value: 5,
        },
        {
            label: "6",
            value: 6,
        },
    ];

    private changeValue(type: string, value: string) {
        if (type == "columns") {
            Vue.set(this.syncedPagination.columns, "items", parseInt(value));
        }
    }
}
