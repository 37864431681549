
import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import HTMLEditor from "@/components/ui/texteditor/HTMLEditor.vue";
@Component({
    components: {
        HTMLEditor,
    },
})
export default class CommentEditor extends Vue {
    @Prop({ type: String, default: "" })
    value!: string;

    @Prop({ type: String, default: "" })
    refreshKey!: string;

    @Prop({ default: false, type: Boolean })
    showCancel!: boolean;

    @Prop({ type: String, default: "Save" })
    saveBtn!: string;

    @Prop({ type: String, default: "mdi-send" })
    saveIcon!: string;

    @Prop({ type: String, default: "Comment..." })
    placeHolder!: string;

    @Prop({ default: false, type: Boolean })
    saving!: boolean;

    private editValue = "";

    get saveActive(): boolean {
        if (
            this.editValue &&
            this.editValue.length &&
            this.editValue != this.value
        ) {
            return true;
        } else {
            return false;
        }
    }

    private cancelComment(): void {
        this.$emit("cancel-comment");
    }

    private updateTextValue(value: string): void {
        this.editValue = value;
    }

    private submitComment(value: string): void {
        this.updateTextValue(value);
        this.$emit("comment-submit", value);
    }

    mounted(): void {
        this.onValueChange(this.value);
    }

    @Watch("value")
    onValueChange(val: string): void {
        this.editValue = val;
    }

    @Watch("refreshKey")
    onKeyChange(): void {
        this.editValue = "";
    }
}
