
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Comment } from "@/graphql/API";
import CommentBlock from "@/components/comments/tab/panel/CommentItem.vue";
import ReplyTree from "@/components/comments/tab/panel/ReplyTree.vue";

@Component({
    name: "CommentTree",
    components: {
        CommentBlock,
        ReplyTree,
    },
})
export default class CommentTree extends Vue {
    @Prop({ default: () => [], type: Array })
    list!: Comment[];

    @Prop({ type: String, default: "desc" })
    order!: string;

    @Prop()
    replyActive!: number | null;

    get comments(): Comment[] {
        return this.sortComments(this.list, this.order);
    }

    private sortComments(comments: Comment[], order: string): Comment[] {
        return comments.sort((a, b) => {
            if (order == "desc") {
                return a.created < b.created ? 1 : -1;
            } else {
                return a.created > b.created ? 1 : -1;
            }
        });
    }
}
