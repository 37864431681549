
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Comment } from "@/graphql/API";
import { getModule } from "vuex-module-decorators";
import Comments from "@/store/modules/Comments";
import CommentBlock from "@/components/comments/tab/panel/CommentItem.vue";

const commentModule = getModule(Comments);

@Component({
    name: "RepyTree",
    components: {
        CommentBlock,
    },
})
export default class ReplyTree extends Vue {
    @Prop({ required: true })
    parentId!: number;

    @Prop({ type: String, default: "desc" })
    order!: string;

    private loading = false;

    get replies(): Comment[] {
        return commentModule.keyedReplies[this.parentId]
            ? commentModule.keyedReplies[this.parentId]
            : [];
    }

    get comments(): Comment[] {
        return this.sortComments(this.replies, this.order);
    }

    private async getReplies(comment_id: number): Promise<void> {
        this.loading = true;
        if (comment_id) {
            await commentModule.fetchReplies(comment_id);
        }
        this.loading = false;
    }

    private sortComments(comments: Comment[], order: string): Comment[] {
        return comments.sort((a, b) => {
            if (order == "desc") {
                return a.created < b.created ? 1 : -1;
            } else {
                return a.created > b.created ? 1 : -1;
            }
        });
    }

    mounted(): void {
        this.onParentIdChange(this.parentId);
    }

    @Watch("parentId")
    onParentIdChange(val: number): void {
        this.getReplies(val);
    }
}
