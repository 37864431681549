import { render, staticRenderFns } from "./WordPreview.vue?vue&type=template&id=670b09a7&scoped=true&"
import script from "./WordPreview.vue?vue&type=script&lang=ts&"
export * from "./WordPreview.vue?vue&type=script&lang=ts&"
import style0 from "./WordPreview.vue?vue&type=style&index=0&id=670b09a7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "670b09a7",
  null
  
)

export default component.exports