import { Component, Vue, Prop, Watch, PropSync } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TabComments from "@/components/mixins/TabComments";
import CommentItems from "@/components/mixins/CommentItems";
import {
    Pagination,
    Choice,
} from "@/graphql/API";

@Component
export default class CommentBase extends mixins(CommentItems) {
    loading = false;

    sidebarIndex = "sidebar-close";
    settingsMode = "tab-settings";
    mainColumnWidth = 400;
    dataColumnWidth = 300;
    showHeader = true;

    paddingX = 2;
    paddingY = 2;
    borderX = true;
    borderY = true;
    fullSingleCol = false;
    showTotal = true;

    rootFactorId = -1;

    pagination: Pagination = {
        columns: {
            all: false,
            items: 20,
            position: 0,
        },
    };

    loadingTabSettings = false;

    /* Set to true if all framework objects are available */
    showAllChoicesTab = false;

    /* Load Options for tab */
    async loadTabOptions(): Promise<void> {
        if (this.tabSettings) {
            await Promise.all([
                this.loadTabUiSettings(
                    this.tabSettings.json != ""
                        ? JSON.parse(this.tabSettings.json)
                        : null
                ),
            ]);
        }
    }

    private async loadTabUiSettings(options: {
        mainColumn: number;
        dataColumn: number;
        paddingX: number;
        paddingY: number;
        borderX: boolean;
        borderY: boolean;
        pagination: Pagination;
        fullSingleCol?: boolean;
        showTotal?: boolean;
    }): Promise<void> {
        if (options) {
            console.log("load options")
            console.log(options);
            this.mainColumnWidth = options.mainColumn;
            this.dataColumnWidth = options.dataColumn;
            this.paddingX = options.paddingX;
            this.paddingY = options.paddingY;
            this.borderX = options.borderX;
            this.borderY = options.borderY;
            this.pagination = options.pagination;
            this.fullSingleCol = options.fullSingleCol
                ? options.fullSingleCol
                : false;
            this.showTotal =
                options.showTotal == true || options.showTotal == false
                    ? options.showTotal
                    : true;
        }
    }

    openSidebar(index: string): void {
        if (this.sidebarIndex != index) {
            this.sidebarIndex = index;
            this.settingsMode = index;
        } else {
            this.sidebarIndex = "sidebar-close";
        }
    }

    @Watch("tabPropId")
    async onTabSettingsChange(): Promise<void> {
        this.loadTabOptions();
    }
}