import { Component, Vue, Prop, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Choices from "@/store/modules/Choices";
import { App, Tab } from "@/graphql/API";
import Apps from "@/store/modules/Apps";
import Decisions from "@/store/modules/Decisions";
import { Urls } from "@/Urls";

const choiceModule = getModule(Choices);
const appsModule = getModule(Apps);
const decisionsModule = getModule(Decisions);

@Component
export default class TabComments extends Vue {
    @Prop({ default: false, type: Boolean })
    isEdit!: boolean;

    @Prop()
    tabSettings!: Tab;

    @Prop()
    app!: App;

    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @Prop()
    header!: string;

    @Prop()
    bannerImage!: string;

    @Prop()
    bannerAlign!: string;

    @Prop()
    page!: string;

    hideChoices = false;

    showAllChoicesTab = false;

    activeChoices: { all: boolean; choices: number[] } = {
        all: false,
        choices: [],
    };

    readonly URLS = Urls;

    get tabId(): number | null {
        if (this.tabSettings) {
            return this.tabSettings.id;
        } else {
            return null;
        }
    }

    get frameworkChoices(): number[] {
        return choiceModule.choices.map((choice) => choice.id);
    }

    /* If view is a app-tab returns array of ids for choices mapped to it */
    get tabChoicesIds(): number[] {
        if (this.tabId && appsModule.tabChoices[this.tabId]) {
            return Object.values(appsModule.tabChoices[this.tabId]).map(
                (item) => item.choice_id
            );
        } else {
            return [];
        }
    }

    /* Properties for controlling page */
    get isMainTool(): boolean {
        return !this.isEdit && !this.embed;
    }

    get decisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get tabLink(): string | null {
        if (this.isEdit && this.app && this.tabSettings) {
            return `${this.URLS.APPS}/${this.app.id}/${this.tabSettings.id}`;
        } else {
            return null;
        }
    }

    addIdList(ids: number[], list: number[]): void {
        ids.forEach((id) => {
            if (!list.includes(id)) {
                list.push(id);
            }
        });
    }

    removeIdList(ids: number[], list: number[]): void {
        ids.forEach((id) => {
            if (list.indexOf(id)) {
                Vue.delete(list, list.indexOf(id));
            }
        });
    }
}