
import { Component, Prop, Vue } from "vue-property-decorator";
import * as mammoth from "mammoth";
import * as XLSX from "xlsx";
import { FactorOptions } from "@/graphql/API";

@Component({
    name: "DocumentPreview",
})
export default class DocumentPreview extends Vue {
    @Prop({ type: String, default: "document.docx" }) fileName!: string;
    @Prop({ required: true }) documentUrl!: string;
    @Prop({ default: false }) enableDownload!: boolean;

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    private docContent = "";
    private sheetContents: string[] = [];
    private sheetNames: string[] = [];
    private fileType = "";
    private unsupportedFileType = false;
    private activeSheet = 0; // Keeps track of the currently selected sheet

    mounted() {
        this.determineFileType();
        this.loadFile();
    }

    beforeDestroy() {
        // Cleanup if necessary (e.g., aborting fetch or cleaning up resources)
    }

    private determineFileType() {
        // Check the file extension to determine the type (docx, xlsx, xls)
        const fileExtension = this.fileName.split(".").pop()?.toLowerCase();

        if (fileExtension === "docx") {
            this.fileType = "docx";
        } else if (fileExtension === "xlsx" || fileExtension === "xls") {
            this.fileType = "xls";
        } else {
            this.unsupportedFileType = true;
        }
    }

    private async loadFile() {
        if (this.unsupportedFileType) return;

        if (this.fileType === "docx") {
            await this.loadDocxFile();
        } else if (this.fileType === "xls" || this.fileType === "xlsx") {
            await this.loadExcelFile();
        }
    }

    private async loadDocxFile() {
        try {
            const response = await fetch(this.documentUrl);
            const arrayBuffer = await response.arrayBuffer();

            const result = await mammoth.convertToHtml({ arrayBuffer });
            this.docContent = result.value;
        } catch (error) {
            console.error("Error loading DOCX file:", error);
        }
    }

    private async loadExcelFile() {
        try {
            const response = await fetch(this.documentUrl);
            const arrayBuffer = await response.arrayBuffer();

            // Read the file (XLS or XLSX) using SheetJS
            const workbook = XLSX.read(arrayBuffer, { type: "array" });

            // Extract all sheet names and their HTML contents
            this.sheetNames = workbook.SheetNames;

            this.sheetContents = this.sheetNames.map((sheetName) => {
                const sheet = workbook.Sheets[sheetName];
                let html = XLSX.utils.sheet_to_html(sheet);

                // Add custom styles to add borders to the table
                html = this.addTableBorders(html);
                return html;
            });
        } catch (error) {
            console.error("Error loading XLS/XLSX file:", error);
        }
    }

    private addTableBorders(html: string): string {
        // Add custom styles for table borders
        const style = `
        <style>
          table {
            border-collapse: collapse;
            width: 100%;
          }
          table, th, td {
            border: 1px solid black;
          }
          th, td {
            padding: 8px;
            text-align: left;
          }
        </style>
      `;

        // Prepend the styles to the HTML content
        return style + html;
    }

    private async downloadDocument() {
        if (!this.documentUrl) return;

        try {
            // Fetch the file as a blob
            const response = await fetch(this.documentUrl);
            const blob = await response.blob();

            // Create a link to trigger the download
            const link = document.createElement("a");

            // Create a download URL for the Blob
            link.href = URL.createObjectURL(blob);

            // Set the desired file name for download
            link.download = this.fileName;

            // Programmatically trigger the download
            link.click();

            // Clean up the URL object after download
            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error("Error downloading document:", error);
        }
    }
}
