
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Factor, Choice, Value } from "@/graphql/API";
import Choices from "@/store/modules/Choices";

const choicesModule = getModule(Choices);

@Component({
    components: {},
})
export default class CommentCell extends Vue {
    @Prop({ required: true })
    choice!: Choice;

    @Prop({ required: true })
    factor!: Factor;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    showTotal!: boolean;

    get value(): Value | null {
        if (
            choicesModule.choiceValues[this.choice.id] &&
            choicesModule.choiceValues[this.choice.id][this.factor.id]
        ) {
            return choicesModule.choiceValues[this.choice.id][this.factor.id];
        } else {
            return null;
        }
    }

    get commentTotal(): number {
        if (this.value) {
            return this.value.comments;
        } else {
            return 0;
        }
    }

    private selectComment(): void {
        this.$emit("comment-select", {
            factor: this.factor,
            choice: this.choice,
        });
    }
}
