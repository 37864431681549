
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Comments from "@/store/modules/Comments";
import { onCommentChange } from "@/graphql/subscriptions";
import { Choice, Factor, Comment } from "@/graphql/API";
import { OnCommentChange } from "@/graphql/custom";
import { GraphQLResult } from "@aws-amplify/api";
import Observable from "zen-observable-ts";
import { API } from "aws-amplify";
import CommentEditor from "@/components/comments/tab/panel/CommentEditor.vue";
import CommentTree from "@/components/comments/tab/panel/CommentTree.vue";
import CommentPanelHeader from "@/components/comments/tab/panel/CommentPanelHeader.vue";

const commentModule = getModule(Comments);

@Component({
    components: {
        CommentEditor,
        CommentTree,
        CommentPanelHeader,
    },
})
export default class CommentSideBar extends Vue {
    @Prop({ required: true })
    factor!: Factor;

    @Prop({ required: true })
    choice!: Choice;

    private initialLoading = false;
    private loading = false;
    private refresh = "";
    private showOrderMenu = false;
    private order = "desc";

    private replyActive: number | null = null;
    private showError = false;

    private commentSub: any = null;

    get index(): string {
        return `${this.choice.id}-${this.factor.id}`;
    }

    get comments(): Comment[] {
        return commentModule.keyedList[this.index]
            ? commentModule.keyedList[this.index]
            : [];
    }

    get currCell(): any {
        return commentModule.currentCell;
    }

    mounted(): void {
        this.onIndexChange();
    }

    unmounted(): void {
        commentModule.clearCurrCell();
    }

    private setActiveReply(val: number | null): void {
        this.replyActive = val;
    }

    private setOrder(val: string): void {
        this.order = val;
    }

    private refreshSideBar(): void {
        const currentDate = new Date();
        this.refresh = currentDate.toString();
    }

    private async saveComment(val: string): Promise<void> {
        this.loading = true;
        if (this.choice && this.factor) {
            await commentModule.createComment({
                choice_id: this.choice.id,
                factor_id: this.factor.id,
                comment: val,
            });
        }
        /* Clear */
        this.refreshSideBar();
        this.loading = false;
    }

    @Watch("index")
    async onIndexChange(): Promise<void> {
        this.initialLoading = true;
        this.refreshSideBar();
        if (this.factor && this.choice) {
            try {
                await Promise.all([
                    commentModule.fetchComments({
                        choice_id: this.choice.id,
                        factor_id: this.factor.id,
                    }),
                    commentModule.setCurrCell({
                        choice_id: this.choice.id,
                        factor_id: this.factor.id,
                    }),
                ]);
            } catch (e) {
                console.log(e);
                this.showError = true;
            }
        }
        this.initialLoading = false;
    }
}
