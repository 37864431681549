
import { Component, Prop, Vue } from "vue-property-decorator";
import * as pdfjsLib from "pdfjs-dist";
import { GlobalWorkerOptions } from "pdfjs-dist";
import { FactorOptions } from "@/graphql/API";

@Component({
    name: "PdfViewer",
})
export default class PdfViewer extends Vue {
    @Prop({ required: true }) pdfUrl!: string;
    @Prop({ type: String, default: "document.pdf" })
    fileName!: string;

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    pdfDocument: any = null;
    currentPage = 1;
    desiredPage = 1;
    totalPages = 0;
    scale = 1.5;
    minScale = 0.5;
    maxScale = 2.0;
    searchQuery = "";

    mounted() {
        GlobalWorkerOptions.workerSrc =
            "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js";
        this.loadPdf();
        this.initDrag();
    }

    async loadPdf(): Promise<void> {
        const loadingTask = pdfjsLib.getDocument(this.pdfUrl);
        try {
            this.pdfDocument = await loadingTask.promise;
            this.totalPages = this.pdfDocument.numPages;
            this.renderPage(this.currentPage);
        } catch (error) {
            console.error("Error loading PDF:", error);
        }
    }

    async renderPage(pageNumber: number): Promise<void> {
        if (!this.pdfDocument) return;

        const pdfCanvas = this.$refs.pdfCanvas as HTMLCanvasElement;
        const highlightCanvas = this.$refs.highlightCanvas as HTMLCanvasElement;
        const canvasWrapper = pdfCanvas.parentElement as HTMLElement;
        const pdfContainer = canvasWrapper.parentElement as HTMLElement;

        const pdfContext = pdfCanvas.getContext(
            "2d"
        ) as CanvasRenderingContext2D;
        const highlightContext = highlightCanvas.getContext(
            "2d"
        ) as CanvasRenderingContext2D;

        try {
            const page = await this.pdfDocument.getPage(pageNumber);
            const viewport = page.getViewport({ scale: this.scale });

            pdfCanvas.width = viewport.width;
            pdfCanvas.height = viewport.height;
            highlightCanvas.width = viewport.width;
            highlightCanvas.height = viewport.height;

            canvasWrapper.style.width = `${viewport.width}px`;
            canvasWrapper.style.height = `${viewport.height}px`;

            const scrollLeftRatio =
                pdfContainer.scrollLeft / (pdfContainer.scrollWidth || 1);
            const scrollTopRatio =
                pdfContainer.scrollTop / (pdfContainer.scrollHeight || 1);

            const renderContext = {
                canvasContext: pdfContext,
                viewport,
            };

            highlightContext.clearRect(
                0,
                0,
                highlightCanvas.width,
                highlightCanvas.height
            );

            await page.render(renderContext);

            const adjustedScrollLeft =
                scrollLeftRatio * pdfContainer.scrollWidth;
            const adjustedScrollTop =
                scrollTopRatio * pdfContainer.scrollHeight;

            pdfContainer.scrollLeft = adjustedScrollLeft;
            pdfContainer.scrollTop = adjustedScrollTop;

            if (this.searchQuery.trim()) {
                const textContent = await page.getTextContent();
                const matches = this.getSearchMatches(
                    textContent,
                    this.searchQuery
                );

                matches.forEach((match) => {
                    const [x, y, width, height] = this.getBoundingBox(
                        match,
                        viewport
                    );
                    highlightContext.fillStyle = "rgba(255, 255, 0, 0.5)";
                    highlightContext.fillRect(x, y, width, height);
                });
            }
        } catch (error) {
            console.error("Error rendering page:", error);
        }
    }

    getSearchMatches(textContent: any, searchQuery: string): any[] {
        const matches: any[] = [];
        const query = searchQuery.toLowerCase();

        textContent.items.forEach((item: any) => {
            if (item.str.toLowerCase().includes(query)) {
                matches.push(item);
            }
        });

        return matches;
    }

    getBoundingBox(item: any, viewport: any): number[] {
        const { transform, width, height } = item;
        const [x, y] = viewport.convertToViewportPoint(
            transform[4],
            transform[5]
        );
        const boxWidth = width * viewport.scale;
        const boxHeight = height * viewport.scale;

        return [x, y - boxHeight, boxWidth, boxHeight];
    }

    goToPreviousPage(): void {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.desiredPage = this.currentPage;
            this.renderPage(this.currentPage);
        }
    }

    goToNextPage(): void {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            this.desiredPage = this.currentPage;
            this.renderPage(this.currentPage);
        }
    }

    zoomIn(): void {
        if (this.scale < this.maxScale) {
            this.scale += 0.1;
            this.renderPage(this.currentPage);
        }
    }

    zoomOut(): void {
        if (this.scale > this.minScale) {
            this.scale -= 0.1;
            this.renderPage(this.currentPage);
        }
    }

    goToPage(): void {
        if (this.desiredPage >= 1 && this.desiredPage <= this.totalPages) {
            this.currentPage = this.desiredPage;
            this.renderPage(this.currentPage);
        }
    }

    findInDocument(): void {
        this.renderPage(this.currentPage);
    }

    downloadPdf(): void {
        if (!this.pdfDocument) return;

        const loadingTask = pdfjsLib.getDocument(this.pdfUrl);
        loadingTask.promise
            .then((pdfDocument) => {
                pdfDocument.getData().then((data) => {
                    // Create a Blob from the PDF data
                    const blob = new Blob([data], { type: "application/pdf" });

                    // Extract the file name from the URL (or default to 'document.pdf' if not found)
                    const fileName = this.fileName || "document.pdf";

                    // Create a URL for the Blob
                    const url = URL.createObjectURL(blob);

                    // Create an anchor element to trigger the download
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = fileName; // Use extracted file name

                    // Programmatically click the link to trigger the download
                    link.click();

                    // Clean up the Blob URL after the download is triggered
                    URL.revokeObjectURL(url);
                });
            })
            .catch((error) => {
                console.error("Error downloading PDF:", error);
            });
    }

    initDrag() {
        const pdfContainer = this.$refs.pdfContainer as HTMLElement;
        let isDragging = false;
        let startX: number;
        let startY: number;
        let scrollLeft: number;
        let scrollTop: number;

        pdfContainer.addEventListener("mousedown", (e) => {
            isDragging = true;
            startX = e.pageX - pdfContainer.offsetLeft;
            startY = e.pageY - pdfContainer.offsetTop;
            scrollLeft = pdfContainer.scrollLeft;
            scrollTop = pdfContainer.scrollTop;
            pdfContainer.classList.add("dragging");
        });

        pdfContainer.addEventListener("mousemove", (e) => {
            if (!isDragging) return;

            const x = e.pageX - pdfContainer.offsetLeft;
            const y = e.pageY - pdfContainer.offsetTop;

            const walkX = (x - startX) * 2;
            const walkY = (y - startY) * 2;

            pdfContainer.scrollLeft = scrollLeft - walkX;
            pdfContainer.scrollTop = scrollTop - walkY;
        });

        pdfContainer.addEventListener("mouseup", () => {
            isDragging = false;
            pdfContainer.classList.remove("dragging");
        });

        pdfContainer.addEventListener("mouseleave", () => {
            isDragging = false;
            pdfContainer.classList.remove("dragging");
        });
    }
}
