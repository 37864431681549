
import { Component, Prop, Vue } from "vue-property-decorator";
import { Choice } from "@/graphql/API";

@Component({
    components: {},
})
export default class CommentChoiceToggle extends Vue {
    @Prop()
    label!: string;

    @Prop({ default: false, type: Boolean })
    active!: boolean;

    @Prop({ default: true, type: Boolean })
    dragEnabled!: boolean;
}
