import { Component, Vue, Prop, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    App,
    Tab,
    Locks,
    ScoreClassSettings,
    ScoreOptions,
    FactorOptions,
    FactorSelection,
    AppOptions,
} from "@/graphql/API";
import Choices from "@/store/modules/Choices";
import Viewpoints from "@/store/modules/Viewpoints";
import Apps from "@/store/modules/Apps";
import Decisions from "@/store/modules/Decisions";
import { Urls } from "@/Urls";

const appsModule = getModule(Apps);
const decisionsModule = getModule(Decisions);
const choiceModule = getModule(Choices);
const viewpointsModule = getModule(Viewpoints);

@Component
export default class TabManagment extends Vue {
    @Prop({ default: false, type: Boolean })
    isEdit!: boolean;

    @Prop()
    tabSettings!: Tab;

    @Prop()
    app!: App;

    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @Prop()
    header!: string;

    @Prop()
    bannerImage!: string;

    @Prop()
    bannerAlign!: string;

    @Prop()
    page!: string;

    @PropSync("routeLock")
    syncedRouteLock!: boolean;

    @PropSync("routeConfirmation")
    syncedRouteConfirmation!: boolean;

    loading = false;

    paddingX = 2;
    paddingY = 2;
    borderX = true;
    borderY = true;
    fullSingleCol = false;

    locks: Locks = {
        choiceEditable: false,
        scoresEditable: false,
        customScore: false,
        weightsEditable: false,
        scoreRuleEditable: false,
        classRating: false,
        classSelector: {
            label: false,
            description: false,
            icons: false,
            colour: false,
        },
        scoreDisplay: {
            bar: false,
            score: false,
            weights: false,
            weights_icon: false,
            normalized_score: false,
            class_label: false,
            class_icons: false,
            class_description: false,
            class_colour: false,
            choice_data: false,
        },
        factorDisplay: {
            factor_description: false,
            value_download: false,
        },
        newChoices: false,
        newViewpoints: false,
        axises: false,
    };

    choiceEditable = true;
    scoresEditable = true;
    customScore = true;
    weightsEditable = true;
    scoreRuleEditable = true;
    classRating = true;
    classSelector: ScoreClassSettings = {
        label: true,
        description: true,
        icons: true,
        colour: true,
        text: "Rating",
    };

    scoreDisplay: ScoreOptions = {
        bar: true,
        score: true,
        weights: true,
        weights_icon: true,
        comments: true,
        normalized_score: true,
        class_label: true,
        class_icons: true,
        class_description: true,
        class_colour: this.page == "RankView",
        choice_data: true,
    };

    factorDisplay: FactorOptions = {
        factor_description: true,
        value_download: false,
    };

    classSelectorLabels = {
        label: "Labels",
        description: "Descriptions",
        icons: "Icons",
        colour: "Color",
    };

    scoreDisplayLabels = {
        bar: "Bar",
        score: "Score",
        weights: "Weights",
        weights_icon: "Weight Icons",
        normalized_score: "Normalized Score",
        class_label: "Class Label",
        class_icons: "Class Icons",
        class_description: "Class Description",
        class_colour: "Class Color",
        choice_data: "Choice Data",
    };

    factorDisplayLabels = {
        factor_description: "Factor Description",
        value_download: "Document Download",
    };

    activeChoices: { all: boolean; choices: number[] } = {
        all: false,
        choices: [],
    };
    activeViewpoints: { all: boolean; viewpoints: number[] } = {
        all: false,
        viewpoints: [],
    };

    activeFactors: FactorSelection = {
        all: false,
        factors: [],
    };

    readonly URLS = Urls;

    /*
    viewpointList: ViewpointItem[] = [];
    choiceList: ChoiceItem[] = [];
    */

    /* Factor IDs that are hidden in a tab */
    hiddenFactors: number[] = [];
    /* Factor IDs that the user toggles off when using sidebar */
    unselectedFactors: number[] = [];

    loadingTabSettings = false;

    queryFactorIds: number[] = [];
    queryChoicesIds: number[] = [];
    queryViewpointsIds: number[] = [];
    querySettings = false;
    routeQuery: { [id: string]: string | (string | null)[] } = {};

    /* Set to true if user selected objects should be auto selected */
    selectUserChoices = false;
    selectUserViewpoints = false;

    /* Set to true if user should see their created objects */
    userChoicesEnabled = false;
    userViewpointsEnabled = false;

    /* Object Select Refactor ################################ */

    /* Set to true if all framework objects are available */
    showAllChoicesTab = false;
    showAllViewpointsTab = false;

    hideChoices = false;
    hideViewpoints = false;

    get frameworkChoices(): number[] {
        return choiceModule.choices.map((choice) => choice.id);
    }

    get frameworkViewpoints(): number[] {
        return viewpointsModule.viewpoints.map((vp) => vp.id);
    }

    get appOptions(): AppOptions {
        if (this.app && this.app.json) {
            return JSON.parse(this.app.json);
        } else {
            return {};
        }
    }

    /* If view is a app-tab returns array of ids for choices mapped to it */
    get tabChoicesIds(): number[] {
        if (this.tabId && appsModule.tabChoices[this.tabId]) {
            return Object.values(appsModule.tabChoices[this.tabId]).map(
                (item) => item.choice_id
            );
        } else if (this.querySettings) {
            return this.queryChoicesIds;
        } else {
            return [];
        }
    }

    /* If view is a app-tab returns array of ids for viewpoints mapped to it */
    get tabViewpointsIds(): number[] {
        if (this.tabId && appsModule.tabViewpoints[this.tabId]) {
            return Object.values(appsModule.tabViewpoints[this.tabId]).map(
                (item) => item.viewpoint_id
            );
        } else if (this.querySettings) {
            return this.queryViewpointsIds;
        } else {
            return [];
        }
    }

    /* End of Object Select Refactor ################################ */

    /* Properties for controlling page */
    get isMainTool(): boolean {
        return !this.isEdit && !this.embed;
    }

    get tabId(): number | null {
        if (this.tabSettings) {
            return this.tabSettings.id;
        } else {
            return null;
        }
    }

    get decisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get pageLoading(): boolean {
        return decisionsModule.dataLoading || this.loadingTabSettings;
    }

    get pageAndFactorsLoading(): boolean {
        return this.pageLoading;
    }

    /* Properties for objects */

    /* If view is a app-tab returns array of ids for choices mapped to it */
    get tabFactorIds(): number[] {
        if (this.tabId && appsModule.tabFactors[this.tabId]) {
            return Object.values(appsModule.tabFactors[this.tabId]).map(
                (item) => item.factor_id
            );
        } else if (this.querySettings) {
            return this.queryFactorIds;
        } else {
            return [];
        }
    }

    get tabSettingsString(): string | null {
        if (this.tabSettings) {
            return JSON.stringify(this.tabSettings);
        } else {
            return null;
        }
    }

    get tabLink(): string | null {
        if (this.isEdit && this.app && this.tabSettings) {
            return `${this.URLS.APPS}/${this.app.id}/${this.tabSettings.id}`;
        } else {
            return null;
        }
    }

    private initializeMainTool(): void {
        this.showAllChoicesTab = true;
        this.showAllViewpointsTab = true;
        Vue.set(this.activeFactors, "all", true);
        /*
        Vue.set(this.activeViewpoints, "all", true);
        Vue.set(this.activeChoices, "all", true);
        */
    }

    beforeMount(): void {
        this.routeQuery = JSON.parse(JSON.stringify(this.$route.query));
    }

    mounted(): void {
        if (this.isMainTool) {
            this.initializeMainTool();
        }
    }

    addIdList(ids: number[], list: number[]): void {
        ids.forEach((id) => {
            if (!list.includes(id)) {
                list.push(id);
            }
        });
    }

    removeIdList(ids: number[], list: number[]): void {
        ids.forEach((id) => {
            if (list.indexOf(id)) {
                Vue.delete(list, list.indexOf(id));
            }
        });
    }

    @Watch("tabFactorIds", { immediate: true, deep: true })
    onTabFactorIdsChange(): void {
        if (this.embed) {
            this.hiddenFactors = [...this.tabFactorIds];
        } else {
            this.unselectedFactors = [...this.tabFactorIds];
        }
    }
}
