
import { Vue, Component, Prop } from "vue-property-decorator";
import { Choice, Factor } from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
import { getModule } from "vuex-module-decorators";

const decisionModule = getModule(Decisions);

@Component({
    name: "CommentPanelHeader",
    components: {},
})
export default class CommentPanelHeader extends Vue {
    @Prop({ required: true })
    factor!: Factor;

    @Prop({ required: true })
    choice!: Choice;

    @Prop({ default: "desc" })
    order!: string;

    private showOrderMenu = false;

    get choiceLabelSingular(): string {
        return decisionModule.choiceLabelSingular;
    }

    private changeOrder(val: string): void {
        this.$emit("change-order", val);
    }
}
