var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!(_vm.embed && _vm.editAllLocked && _vm.displayAllLocked))?_c('v-expansion-panels',{staticClass:"vp-accordion",attrs:{"accordion":"","flat":""}},[(!(_vm.embed && _vm.editAllLocked))?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',[_vm._v("mdi-menu-down")])]},proxy:true}],null,false,958259542)},[_c('h3',[_vm._v("Edit Options")])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"vp-choice-score__sidebar"},[(!_vm.embed)?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"flex-grow-1"},[_vm._v("All")]),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.lockEdits(!_vm.editAllLocked)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.editAllLocked ? "mdi-lock" : "mdi-lock-open-outline")+" ")])],1)],1):_vm._e(),_c('div',{staticClass:"vp-choice-score__sidebar__section"},[(!(_vm.embed && _vm.syncedLocks['choiceEditable']))?_c('div',{staticClass:"vp-choice-score__sidebar__toggle"},[_c('v-switch',{staticClass:"vp-toggle",attrs:{"dense":"","hide-details":"","disabled":_vm.syncedLocks['choiceEditable'],"label":`Edit ${_vm.choiceLabel}`},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}},model:{value:(_vm.syncedChoiceEditable),callback:function ($$v) {_vm.syncedChoiceEditable=$$v},expression:"syncedChoiceEditable"}}),(!_vm.embed)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.syncedLocks['choiceEditable'] =
                                    !_vm.syncedLocks['choiceEditable']}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.syncedLocks["choiceEditable"] ? "mdi-lock" : "mdi-lock-open-outline")+" ")])],1):_vm._e()],1):_vm._e(),(!(_vm.embed && _vm.syncedLocks['scoresEditable']))?_c('div',{staticClass:"vp-choice-score__sidebar__toggle"},[_c('v-switch',{staticClass:"vp-toggle",attrs:{"dense":"","hide-details":"","disabled":_vm.syncedLocks['scoresEditable'],"label":"Edit Scores"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}},model:{value:(_vm.syncedScoresEditable),callback:function ($$v) {_vm.syncedScoresEditable=$$v},expression:"syncedScoresEditable"}}),(!_vm.embed)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.syncedLocks['scoresEditable'] =
                                    !_vm.syncedLocks['scoresEditable']}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.syncedLocks["scoresEditable"] ? "mdi-lock" : "mdi-lock-open-outline")+" ")])],1):_vm._e()],1):_vm._e(),(!(_vm.embed && _vm.syncedLocks['classRating']))?_c('div',{staticClass:"vp-choice-score__sidebar__toggle"},[_c('v-switch',{staticClass:"vp-toggle",attrs:{"dense":"","hide-details":"","label":"Edit Class","disabled":_vm.syncedLocks['classRating']},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}},model:{value:(_vm.syncedClassRating),callback:function ($$v) {_vm.syncedClassRating=$$v},expression:"syncedClassRating"}}),(!_vm.embed)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.syncedLocks['classRating'] =
                                    !_vm.syncedLocks['classRating']}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.syncedLocks["classRating"] ? "mdi-lock" : "mdi-lock-open-outline")+" ")])],1):_vm._e()],1):_vm._e(),(!(_vm.embed && _vm.syncedLocks['weightsEditable']))?_c('div',{staticClass:"vp-choice-score__sidebar__toggle"},[_c('v-switch',{staticClass:"vp-toggle",attrs:{"dense":"","hide-details":"","label":"Edit Weights","disabled":_vm.syncedLocks['weightsEditable']},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}},model:{value:(_vm.syncedWeightsEditable),callback:function ($$v) {_vm.syncedWeightsEditable=$$v},expression:"syncedWeightsEditable"}}),(!_vm.embed)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.syncedLocks['weightsEditable'] =
                                    !_vm.syncedLocks['weightsEditable']}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.syncedLocks["weightsEditable"] ? "mdi-lock" : "mdi-lock-open-outline")+" ")])],1):_vm._e()],1):_vm._e(),(!(_vm.embed && _vm.syncedLocks['scoreRuleEditable']))?_c('div',{staticClass:"vp-choice-score__sidebar__toggle"},[_c('v-switch',{staticClass:"vp-toggle",attrs:{"dense":"","hide-details":"","label":"Edit Score Rule","disabled":_vm.syncedLocks['scoreRuleEditable']},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}},model:{value:(_vm.syncedScoreRuleEditable),callback:function ($$v) {_vm.syncedScoreRuleEditable=$$v},expression:"syncedScoreRuleEditable"}}),(!_vm.embed)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.syncedLocks['scoreRuleEditable'] =
                                    !_vm.syncedLocks['scoreRuleEditable']}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.syncedLocks["scoreRuleEditable"] ? "mdi-lock" : "mdi-lock-open-outline")+" ")])],1):_vm._e()],1):_vm._e()])])])],1):_vm._e(),(!(_vm.embed && _vm.displayAllLocked))?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',[_vm._v("mdi-menu-down")])]},proxy:true}],null,false,958259542)},[_c('h3',[_vm._v("Display Options")])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"vp-choice-score__sidebar__section"},[(!(_vm.embed && _vm.scoreDisplayAllLocked))?[(!_vm.embed)?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"flex-grow-1"},[_vm._v("All")]),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.lockDisplays(!_vm.displayAllLocked)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.displayAllLocked ? "mdi-lock" : "mdi-lock-open-outline")+" ")])],1)],1):_vm._e(),_vm._l((_vm.syncedScoreDisplay),function(toggle,index){return _c('div',{key:index},[(
                                !(
                                    _vm.embed && _vm.syncedLocks.scoreDisplay[index]
                                ) && index != 'weights_icon'
                            )?_c('div',{staticClass:"vp-choice-score__sidebar__toggle"},[_c('v-switch',{staticClass:"vp-toggle",attrs:{"dense":"","hide-details":"","disabled":_vm.syncedLocks.scoreDisplay[index],"label":_vm.scoreDisplayLabels[index]},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}},model:{value:(_vm.syncedScoreDisplay[index]),callback:function ($$v) {_vm.$set(_vm.syncedScoreDisplay, index, $$v)},expression:"syncedScoreDisplay[index]"}}),(!_vm.embed)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.syncedLocks.scoreDisplay[index] =
                                        !_vm.syncedLocks.scoreDisplay[index]}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.syncedLocks.scoreDisplay[index] ? "mdi-lock" : "mdi-lock-open-outline")+" ")])],1):_vm._e()],1):_vm._e()])})]:_vm._e(),(!(_vm.embed && _vm.classSelectorAllLocked))?[_c('h4',{staticClass:"mt-3"},[_vm._v("Class Selector")]),_vm._l((_vm.syncedClassSelector),function(toggle,index){return _c('div',{key:index},[(
                                index != 'text' &&
                                !(
                                    _vm.embed &&
                                    _vm.syncedLocks.classSelector[index]
                                ) &&
                                index != 'description'
                            )?_c('div',{staticClass:"vp-choice-score__sidebar__toggle"},[_c('v-switch',{staticClass:"vp-toggle",attrs:{"dense":"","hide-details":"","disabled":_vm.syncedLocks.classSelector[index],"label":_vm.classSelectorLabels[index]},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}},model:{value:(_vm.syncedClassSelector[index]),callback:function ($$v) {_vm.$set(_vm.syncedClassSelector, index, $$v)},expression:"syncedClassSelector[index]"}}),(!_vm.embed)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.syncedLocks.classSelector[index] =
                                        !_vm.syncedLocks.classSelector[index]}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.syncedLocks.classSelector[index] ? "mdi-lock" : "mdi-lock-open-outline")+" ")])],1):_vm._e()],1):_vm._e()])}),(!_vm.embed)?_c('div',{staticClass:"mt-5"},[_c('v-text-field',{attrs:{"label":"Place Holder","clearable":"","dense":"","hide-details":""},model:{value:(_vm.syncedClassSelector['text']),callback:function ($$v) {_vm.$set(_vm.syncedClassSelector, 'text', $$v)},expression:"syncedClassSelector['text']"}})],1):_vm._e()]:_vm._e(),(!(_vm.embed && _vm.factorAllLocked))?[_c('h4',{staticClass:"mt-3"},[_vm._v("Factor")]),_vm._l((_vm.syncedFactorDisplay),function(toggle,index){return _c('div',{key:index},[(
                                !(_vm.embed && _vm.syncedLocks.factorDisplay[index])
                            )?_c('div',{staticClass:"vp-choice-score__sidebar__toggle"},[_c('v-switch',{staticClass:"vp-toggle",attrs:{"dense":"","hide-details":"","disabled":_vm.syncedLocks.factorDisplay[index],"label":_vm.factorDisplayLabels[index]},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}},model:{value:(_vm.syncedFactorDisplay[index]),callback:function ($$v) {_vm.$set(_vm.syncedFactorDisplay, index, $$v)},expression:"syncedFactorDisplay[index]"}}),(!_vm.embed)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.syncedLocks.factorDisplay[index] =
                                        !_vm.syncedLocks.factorDisplay[index]}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.syncedLocks.factorDisplay[index] ? "mdi-lock" : "mdi-lock-open-outline")+" ")])],1):_vm._e()],1):_vm._e()])})]:_vm._e()],2)])],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }