
import { Component, Watch, Prop, PropSync, Vue } from "vue-property-decorator";
import {
    Choice,
    Viewpoint,
    Locks,
    AppOptions,
    ViewpointItem,
    ChoiceItem,
} from "@/graphql/API";
import ChoiceToggle from "@/components/ui/ChoiceToggle.vue";

@Component({
    components: {
        ChoiceToggle,
    },
})
export default class CommentObjects extends Vue {
    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @Prop({ default: true, type: Boolean })
    isTab!: boolean;

    @PropSync("choiceList")
    syncedChoiceList!: number[];

    @Prop()
    appOptions!: AppOptions;

    @PropSync("locks")
    syncedLocks!: Locks;

    @PropSync("userChoicesEnabled")
    syncedUserChoicesEnabled!: boolean;

    @PropSync("choiceIds")
    syncedChoiceIds!: number[];

    @PropSync("choiceDef")
    syncedChoiceDef!: boolean;

    @Prop({ default: () => [], type: Array })
    userChoices!: number[];

    @PropSync("selectUserChoices")
    syncedSelectUserChoices!: boolean;

    get createChoice(): boolean {
        if (this.appOptions && this.appOptions.userChoice) {
            return true;
        } else {
            return false;
        }
    }

    private selectChoice(payload: {
        choice: Choice | null;
        isCopy: boolean;
    }): void {
        console.log(payload);
    }
}
