
import { Vue, Component, Prop } from "vue-property-decorator";
import vuetify from "@/plugins/vuetify";
@Component({
    components: {},
})
export default class CompareRow extends Vue {
    @Prop({ type: String, default: "" })
    customClass!: string;

    @Prop({ default: false, type: Boolean })
    active!: boolean;

    @Prop({ default: false, type: Boolean })
    shadow!: boolean;

    get shadowClass(): string {
        return this.shadow ? " vp-compare__row--shadow" : " "
    }

    get rowClass(): string {
        if (this.active != false) {
            return (
                this.customClass +
                this.shadowClass +
                " vp-compare__row--expanded"
            );
        } else {
            return this.customClass + this.shadowClass;
        }
    }
}
