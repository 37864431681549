
import { Vue, Component, Prop } from "vue-property-decorator";
import { Factor, Choice, Viewpoint, Value } from "@/graphql/API";
import { getModule } from "vuex-module-decorators";
import Choices from "@/store/modules/Choices";

const choiceModule = getModule(Choices);

@Component({
    components: {},
})
export default class CompareComment extends Vue {
    @Prop()
    dataItem!: Factor | Choice | Viewpoint | null;

    @Prop()
    rowItem!: Factor | Choice | Viewpoint | null;

    @Prop({ default: false, type: Boolean })
    group!: boolean;

    @Prop({
        default: () => ({
            rows: "factors",
            columns: "viewpoints",
            data: "choices",
        }),
        type: Object,
    })
    indexes!: {
        rows: "viewpoints" | "factors" | "choices";
        columns: "viewpoints" | "factors" | "choices";
        data: "viewpoints" | "factors" | "choices";
    };

    get factor(): Factor | null {
        if (this.indexes["rows"] == "factors") {
            return this.rowItem as Factor;
        } else if (this.indexes["data"] == "factors" && this.dataItem) {
            return this.dataItem as Factor;
        } else {
            return null;
        }
    }

    get choice(): Choice | null {
        if (this.indexes["rows"] == "choices") {
            return this.rowItem as Choice;
        } else if (this.indexes["data"] == "choices" && this.dataItem) {
            return this.dataItem as Choice;
        } else {
            return null;
        }
    }

    get value(): Value | null {
        if (this.factor && this.choice) {
            if (
                choiceModule.choiceValues[this.choice.id] &&
                choiceModule.choiceValues[this.choice.id][this.factor.id]
            ) {
                return choiceModule.choiceValues[this.choice.id][this.factor.id];
            }
        }
        return null;
    }

    get commentsExist(): boolean {
        if (this.value && this.value.comments > 0) {
            return true;
        } else {
            return false;
        }
    }

    private toggleComment(): void {
        this.$emit("comment-select", {
            factor: this.factor,
            choice: this.choice,
        });
    }
}
