
import { Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import Viewpoints from "@/store/modules/Viewpoints";
import Factors from "@/store/modules/Factors";
import FlashNotifications from "@/store/modules/FlashNotifications";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import CompareBase from "@/components/mixins/CompareBase";
import { Factor, Viewpoint, Choice, Tab } from "@/graphql/API";
import ScoreRuleDialog from "@/components/scoring/ScoreRuleDialog.vue";
import CompareObjects from "@/components/compare/CompareObjects.vue";
import FactorNav from "@/components/model/FactorNav.vue";
import ViewAppCreator from "@/components/apps/ViewAppCreator.vue";
import CompareSettings from "@/components/compare/CompareSettings.vue";
import CompareOptions from "@/components/compare/CompareOptions.vue";
import RankTable from "@/components/ranking/RankTable.vue";
import WeightCounterMenu from "@/components/weights/WeightCounterMenu.vue";
import { saveTab } from "@/helpers/TabHelper";
import ComparePagination from "@/components/compare/ui/ComparePagination.vue";
import FactorToggleGroup from "@/components/model/FactorToggle/FactorToggleGroup.vue";
import UnSavedChanges from "@/components/ui/UnSavedChanges.vue";
import Comments from "@/store/modules/Comments";

const viewpointsModule = getModule(Viewpoints);
const modelModule = getModule(Factors);
const flashModule = getModule(FlashNotifications);

@Component({
    components: {
        ViewWrapper,
        ScoreRuleDialog,
        CompareObjects,
        FactorNav,
        ViewAppCreator,
        CompareSettings,
        CompareOptions,
        RankTable,
        WeightCounterMenu,
        ComparePagination,
        FactorToggleGroup,
        UnSavedChanges,
    },
})
export default class RankView extends mixins(CompareBase) {
    private editFactor: Factor | Choice | Viewpoint | null = null;
    private editViewpoint: Viewpoint | null = null;

    private selectedViewpoint: Viewpoint | null = null;
    private selectedChoice: Choice | null = null;
    private sortOrder = "desc";

    private selectedFactor: Factor | null = null;

    private darkBorder = true;

    private highlightChoice = true;

    private shareLinkOpen = false;

    private orderDropdown = [
        {
            value: "desc",
            label: "High - Low",
        },
        {
            value: "asc",
            label: "Low - High",
        },
        {
            value: "alph",
            label: "Alphabetical",
        },
    ];

    get pageTitle(): string {
        if (this.isMainTool || !(this.tabSettings && this.tabSettings.title)) {
            return "Ranking";
        } else {
            return this.tabSettings.title;
        }
    }

    get rankViewpoints(): Viewpoint[] {
        if (!this.pagination.columns.all) {
            return this.viewpoints.slice(
                this.pagination.columns.position,
                this.pagination.columns.position + this.pagination.columns.items
            );
        } else {
            return this.viewpoints;
        }
    }

    get rankChoices(): Choice[] {
        return this.choices;
    }

    get columnPages(): number {
        if (!this.pagination.columns.all && this.pagination.columns.items > 0) {
            return this.viewpoints.length - this.pagination.columns.items;
        } else {
            return 0;
        }
    }

    get currentSettings(): Tab {
        if (this.tabSettings) {
            return {
                ...this.tabSettings,
                json: this.currentUiOptions,
                edit_flags: this.currentEditFlags,
                display_flags: this.currentDisplayFlags,
                filter_type: "factor",
                row_type: "choice",
                column_type: "viewpoint",
                default_filter_id: this.dataItem ? this.dataItem.id : undefined,
            };
        } else {
            return {
                id: -1,
                decision_id: this.decisionId ? this.decisionId : -1,
                title: "Rank Screen",
                json: this.currentUiOptions,
                edit_flags: this.currentEditFlags,
                display_flags: this.currentDisplayFlags,
                filter_type: "factor",
                row_type: "choice",
                column_type: "viewpoint",
                default_filter_id: this.dataItem ? this.dataItem.id : undefined,
                type: "RankView",
            };
        }
    }

    get rankFactor(): Factor | null {
        if (this.dataItem && modelModule.factorMap[this.dataItem.id]) {
            return modelModule.factorMap[this.dataItem.id];
        } else {
            return null;
        }
    }

    get rankFactorParentId(): number | null {
        if (this.rankFactor && this.rankFactor.parent_id) {
            return this.rankFactor.parent_id;
        } else {
            return null;
        }
    }

    get currentSettingsString(): string {
        return JSON.stringify(this.currentSettings);
    }

    /* Returns true if current tab settings are different than current configs */
    get settingsChanged(): boolean {
        if (this.tabSettingsString && this.isEdit) {
            return (
                this.tabSettingsString.localeCompare(
                    this.currentSettingsString
                ) != 0
            );
        } else {
            return false;
        }
    }

    get totalChanged(): boolean {
        return (
            this.settingsChanged ||
            this.choicesChanged ||
            this.viewpointsChanged ||
            this.factorsChanged
        );
    }

    private openScoreRule(): void {
        this.editFactor = this.dataItem;
        this.editViewpoint = this.selectedViewpoint;
    }

    private closeDialog(): void {
        this.editFactor = null;
        this.editViewpoint = null;
    }

    private selectChoice(choice: Choice): void {
        this.selectedChoice = choice;
    }

    private toggleViewpoint(viewpoint: Viewpoint): void {
        if (
            this.selectedViewpoint &&
            this.selectedViewpoint.id == viewpoint.id
        ) {
            this.selectedViewpoint = null;
            this.settingsMode = "tab-settings";
        } else {
            this.selectedViewpoint = viewpoint;
        }
    }

    private toggleFactor(payload: { factor: Factor; viewpoint: Viewpoint }) {
        if (payload.factor && payload.viewpoint) {
            this.selectedFactor = payload.factor;
            this.selectedViewpoint = payload.viewpoint;
        }
    }

    private async saveSettings(): Promise<void> {
        if (this.tabSettings && this.isEdit) {
            this.loading = true;
            await saveTab({
                tab: {
                    ...this.currentSettings,
                },
                new: false,
                factors: this.deactiveFactors,
                choices: this.selectedChoices,
                viewpoints: this.selectedViewpoints,
            });
            this.loading = false;
        } else {
            this.appCreateOpen = true;
        }
    }

    private async loadVpMappings(viewpoints: Viewpoint[]): Promise<void> {
        if (viewpoints.length) {
            await Promise.all(
                viewpoints.map(async (viewpoint) => {
                    if (viewpoint) {
                        viewpointsModule.fetchViewpointMappings({
                            viewpoint_id: viewpoint.id,
                            root_only: this.rankFactorParentId
                                ? undefined
                                : true,
                            parent_id: this.rankFactorParentId
                                ? this.rankFactorParentId
                                : undefined,
                        });
                    }
                })
            );
        }
    }

    private showViewpointTooltip(viewpoint: Viewpoint): boolean {
        return viewpoint.description ? viewpoint.description.length > 0 : false;
    }

    private openSaveWarning(): void {
        this.unSavedWarningOpen = true;
    }

    private async dialogSave(): Promise<void> {
        this.unSavedWarningOpen = false;
        await this.saveSettings();
        if (this.tabLink) {
            window.open(this.tabLink, "_self");
        }
    }

    private async copyShareLink(): Promise<void> {
        if (!this.shareLinkOpen) {
            this.shareLinkOpen = true;
            if (this.shareLink.length > 0) {
                await navigator.clipboard.writeText(this.shareLink);
                flashModule.success({
                    message: "Link Copied",
                    duration: 3000,
                });
            }
        }
    }

    async mounted(): Promise<void> {
        this.onActiveViewpointsChange(this.rankViewpoints, []);
    }

    @Watch("rankViewpoints", { deep: true })
    async onActiveViewpointsChange(
        newVal: Viewpoint[],
        oldVal: Viewpoint[] = []
    ): Promise<void> {
        if (newVal && newVal.length) {
            this.loadVpMappings(newVal.filter((x) => !oldVal.includes(x)));
        }
    }

    @Watch("rootFactorId")
    onRootFactorChange(): void {
        if (
            this.dataItem &&
            this.rootFactorId != -1 &&
            this.dataItem.id != this.rootFactorId
        ) {
            if (!this.isFactorInRoot(this.dataItem.id)) {
                this.dataItem = null;
                this.chosenFactor = [];
            }
        }
    }

    @Watch("activeFactors", { deep: true })
    onActiveFactorsChange(): void {
        if (this.dataItem) {
            if (!this.activeFactors.factors.includes(this.dataItem.id)) {
                this.dataItem = null;
                this.chosenFactor = [];
            }
        }
    }

    @Watch("rankFactorParentId")
    onRankFactorParentChange(): void {
        this.onActiveViewpointsChange(this.rankViewpoints, []);
    }

    @Watch("chosenFactor", { deep: true })
    onChosenFactorChange(): void {
        if (!this.selectedFactorLoading) {
            this.loadedDataItemId = null;
            if (this.chosenFactor.length) {
                this.dataItem = modelModule.factorMap[this.chosenFactor[0]];
            } else {
                this.dataItem = null;
            }
            this.factorSelectOpen = false;
        }
    }

    private toggleComments(payload: {
        factor: Factor;
        viewpoint: Viewpoint;
        choice: Choice;
    }) {
        if (payload.factor && payload.choice) {
            if (this.sidebarIndex != "comment-view")
                this.openSidebar("comment-view");
            this.selectedFactor = payload.factor;
            this.selectedChoice = payload.choice;
            this.selectedViewpoint = payload.viewpoint ?? -1;
            this.settingsMode = "comment-view";
        }
    }
}
