import {
    Action,
    config,
    Module,
    Mutation,
    VuexModule,
    getModule,
} from "vuex-module-decorators";
import store from "..";
import { API, graphqlOperation } from "aws-amplify";
import {
    getChoices,
    getFactorValues,
    getValuesByC,
    getValuesByD,
} from "@/graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import {
    SetValue,
    GetChoices,
    GetValuesByC,
    GetValuesByD,
    OnValueChange,
    DeleteValueL,
    UpdateChoice,
    DeleteChoice,
    GetFactorValues,
    CreateValueL,
    UpdateValueL,
    MoveRowL,
    CopyChoice,
    CreateChoiceL,
} from "@/graphql/custom";
import Vue from "vue";
import {
    copyChoice,
    createChoiceL,
    createValueL,
    deleteChoice,
    deleteValueL,
    moveRowL,
    updateChoice,
    updateValueL,
} from "@/graphql/mutations";
import { Changes, Choice, ChoiceCopyInput, Value, Comment } from "@/graphql/API";
import { GraphqlAPI, GRAPHQL_API } from "@/graphql/GraphqlAPI";
import { graphql } from "graphql";
import Users from "@/store/modules/Users";
import Scoring from "./Scoring";

config.rawError = true;

const name = "choices";
if (module.hot) {
    if (store.hasModule(name)) {
        store.unregisterModule(name);
    }
}

@Module({ dynamic: true, store: store, name: name, namespaced: true })
export default class Choices extends VuexModule {
    choices: Choice[] = [];
    selectedChoiceId: number | null = null;
    choiceValues: { [choice_id: number]: { [id: string]: Value } } = {};
    createdDeletedChoiceId: number | null = null;

    get values(): { [id: number]: Value } {
        if (this.selectedChoiceId != null) {
            return this.choiceValues[this.selectedChoiceId];
        } else return {};
    }

    get choiceList(): { [id: number]: Choice } {
        return this.choices.reduce((acc, a) => {
            return {
                ...acc,
                [a.id]: a,
            };
        }, {});
    }

    get currentUserId(): string | null {
        return getModule(Users).currentUserId;
    }

    get userChoices(): Choice[] {
        if (this.currentUserId) {
            return this.choices.filter((choice) => {
                if (this.currentUserId && choice?.owner) {
                    return choice.owner.localeCompare(this.currentUserId) == 0;
                } else {
                    return false;
                }
            });
        } else {
            return [];
        }
    }

    @Mutation
    clear(): void {
        this.choices = [];
        this.choiceValues = {};
    }

    @Mutation
    setChoices(choices: Choice[] | null): void {
        if (choices == null) {
            this.choices = [];
        } else this.choices = choices;
    }

    @Mutation
    setChoice(choice: Choice | null): void {
        if (choice == null) {
            return;
        }

        if (this.choices == null) {
            this.choices = [];
        }

        //Search for choice in current list of choices
        const choiceIndex = this.choices.findIndex(
            (searchChoice) => searchChoice.id === choice.id
        );
        //If found then update choice with new updated choice - else add choice to list
        if (choiceIndex >= 0) {
            this.choices.splice(choiceIndex, 1, choice);
        } else {
            this.choices.push(choice);
        }
    }

    @Mutation
    removeChoice(choice: Choice | null): void {
        if (choice == null) {
            return;
        }

        if (this.choices == null) {
            this.choices = [];
        }

        //Search for choice in current list of choices
        const choiceIndex = this.choices.findIndex(
            (searchChoice) => searchChoice.id === choice.id
        );
        //If found then delete choice
        if (choiceIndex >= 0) {
            this.choices.splice(choiceIndex, 1);
        }
    }

    @Mutation
    selectChoice(id: number | null): void {
        this.selectedChoiceId = id;
    }

    @Mutation
    setcreatedDeletedChoiceId(id: number | null): void {
        this.createdDeletedChoiceId = id;
    }

    // @Mutation
    // async setFactorTreeLevel(payload: {parentId: number, factors: any[]}) {
    //     // if(this.factorTree[payload.parentId] != null) {
    //     //     this.factorTree[payload.parentId].push(payload.factors);
    //     // } else {
    //     Vue.set(this.factorTree, payload.parentId, payload.factors);
    //     // }
    // }

    @Mutation
    setChoiceValues(payload: {
        values: Value[] | null;
        choice_id: number;
    }): void {
        if (payload.values != null) {
            for (const value of payload.values) {
                if (this.choiceValues[value.choice_id] == null) {
                    Vue.set(this.choiceValues, value.choice_id, {});
                }
                Vue.set(
                    this.choiceValues[value.choice_id],
                    value.factor_id +
                        (value.row_id != null && value.row_id != ""
                            ? `-${value.row_id}`
                            : ""),
                    value
                );
            }
        }
    }

    @Mutation
    setValue(value: Value): void {
        const factor_id =
            value.factor_id +
            (value.row_id && value.row_id != "" ? `-${value.row_id}` : "");
        if (this.choiceValues.hasOwnProperty(value.choice_id)) {
            Vue.set(this.choiceValues[value.choice_id], factor_id, value);
        } else {
            const createdValue = {};
            Vue.set(createdValue, value.factor_id, value);
            Vue.set(this.choiceValues, value.choice_id, createdValue);
        }
    }

    @Mutation
    removeValue(value: Value): void {
        const factor_id =
            value.factor_id +
            (value.row_id && value.row_id != "" ? `-${value.row_id}` : "");
        if (this.choiceValues.hasOwnProperty(value.choice_id)) {
            Vue.delete(this.choiceValues[value.choice_id], factor_id);
        }
    }

    @Action
    async fetchChoices(decisionId: number): Promise<Choice[]> {
        if (decisionId != null) {
            this.clear();
            const choices = (await GRAPHQL_API.graphqlQueryRequest({
                query: getChoices,
                variables: {
                    decision_id: decisionId,
                },
            })) as GraphQLResult<GetChoices>;
            if (choices.data?.getChoices) {
                this.setChoices(choices.data?.getChoices || null);
            }
            return choices.data?.getChoices || [];
        }
        return [];
    }

    @Action
    async createChoiceL(payload: {
        name: string;
        decision_id: number;
        description?: string;
    }): Promise<Choice | null> {
        const name = payload.name;
        const decision_id = payload.decision_id;
        const description = payload.description;
        if (name != null && decision_id != null) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: createChoiceL,
                variables: { name, decision_id, description },
            })) as GraphQLResult<CreateChoiceL>;
            if (res != null) {
                const choice = res.data?.createChoiceL?.choices[0];
                if (choice != null) {
                    this.setcreatedDeletedChoiceId(choice.id);
                    this.setChoice(choice);
                    this.selectChoice(choice.id);
                    return choice;
                }
                return null;
            }
        }
        return null;
    }

    @Action
    async copyChoice(input: ChoiceCopyInput): Promise<Choice | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: copyChoice,
            variables: {
                input,
            },
        })) as GraphQLResult<CopyChoice>;
        const choice = res.data?.copyChoice?.choices[0];
        if (choice != null) {
            this.setcreatedDeletedChoiceId(choice.id);
            this.setChoice(choice);
            this.selectChoice(choice.id);
            return choice;
        }
        return null;
    }

    @Action
    async deleteChoice(id: number): Promise<Choice | null> {
        if (id != null) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: deleteChoice,
                variables: {
                    id,
                },
            })) as GraphQLResult<DeleteChoice>;
            const choice = res.data?.deleteChoice?.choices[0];
            if (choice != null) {
                if (this.selectedChoiceId == choice.id) {
                    this.selectChoice(null);
                }
                this.removeChoice(choice);
                return choice;
            }
            return null;
        }
        return null;
    }

    @Action
    async updateChoice(payload: {
        name: string;
        choiceId: number;
        description?: string;
    }): Promise<Choice | null> {
        const name = payload.name;
        const id = payload.choiceId;
        const description = payload.description;
        if (name != null && id != null) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: updateChoice,
                variables: {
                    name,
                    id,
                    description,
                },
            })) as GraphQLResult<UpdateChoice>;
            const choice = res.data?.updateChoice?.choices[0];
            if (choice) {
                this.setChoice(choice);
            }
            return res.data?.updateChoice?.choices[0] || null;
        }
        return null;
    }

    @Action
    async getChoiceValues(payload: {
        choice_id: number | null;
        root_only?: boolean;
        parent_id?: number;
    }): Promise<void> {
        const choice_id = payload.choice_id;
        const parent_id = payload.parent_id;
        const root_only = payload.root_only !== undefined ? payload.root_only : false;
        if (choice_id != null) {
            const choices = (await GRAPHQL_API.graphqlQueryRequest({
                query: getValuesByC,
                variables: {
                    choice_id: choice_id,
                    parent_id: parent_id,
                    root_only: root_only,
                },
            })) as GraphQLResult<GetValuesByC>;
            const values = choices.data?.getValuesByC;
            if (values) {
                this.setChoiceValues({
                    values: values || null,
                    choice_id: choice_id,
                });
            }
        }
    }

    @Action
    async getDecisionValues(payload: {
        decision_id: number | null;
        root_only?: boolean;
        parent_id?: number;
    }): Promise<void> {
        const decision_id = payload.decision_id;
        const parent_id = payload.parent_id;
        const root_only = payload.root_only;

        if (decision_id != null) {
            const choices = (await GRAPHQL_API.graphqlQueryRequest({
                query: getValuesByD,
                variables: {
                    decision_id: decision_id,
                    parent_id: parent_id,
                    root_only: root_only,
                },
            })) as GraphQLResult<GetValuesByD>;
            const values = choices.data?.getValuesByD;
            if (values) {
                values.forEach((value) => {
                    this.setChoiceValues({
                        values: values || null,
                        choice_id: value.choice_id,
                    });
                });
            }
        }
    }

    @Action
    async getFactorValues(factor_id: number | null): Promise<Value[] | null> {
        if (factor_id != null) {
            const res = (await GRAPHQL_API.graphqlQueryRequest({
                query: getFactorValues,
                variables: {
                    factor_id,
                },
            })) as GraphQLResult<GetFactorValues>;
            const values = res.data?.getFactorValues;
            if (values) {
                return values;
            }
        }
        return [];
    }

    //If using enum then value should be left as undefined, if using string value then enum_value_id should be left as undefined
    @Action
    async createValueL(payload: {
        choice_id: number;
        factor_id: number;
        row_id: string;
        value?: string;
        enum_value_id?: number;
        json?: string;
    }): Promise<Changes | null> {
        const choice_id = payload.choice_id;
        const factor_id = payload.factor_id;
        const row_id = payload.row_id ? payload.row_id : "";
        const value = payload.value;
        const json = payload.json;
        const enum_value_id = payload.enum_value_id;

        if (choice_id != null && factor_id != null) {
            const res = (await GRAPHQL_API.graphqlQueryRequest({
                query: createValueL,
                variables: {
                    choice_id,
                    factor_id,
                    row_id,
                    value,
                    json,
                    enum_value_id,
                },
            })) as GraphQLResult<CreateValueL>;
            if (res.data && res.data.createValueL != null) {
                const value = res.data.createValueL.values[0];
                this.setValue(value);
                const scores = res.data.createValueL.scores;
                const scoreMod = getModule(Scoring);
                scoreMod.setScores({ scores: scores });

                return res.data.createValueL;
            }
        }
        return null;
    }

    @Action
    async updateValueL(payload: {
        choice_id: number;
        factor_id: number;
        row_id: string;
        value?: string | null;
        enum_value_id?: number | null;
        json?: string | null;
    }): Promise<Changes | null> {
        const choice_id = payload.choice_id;
        const factor_id = payload.factor_id;
        const row_id = payload.row_id ? payload.row_id : "";
        const value = payload.value;
        const json = payload.json;
        const enum_value_id = payload.enum_value_id;

        if (choice_id != null && factor_id != null) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: updateValueL,
                variables: {
                    choice_id,
                    factor_id,
                    row_id,
                    value,
                    json,
                    enum_value_id,
                },
            })) as GraphQLResult<UpdateValueL>;
            if (res.data && res.data.updateValueL != null) {
                const value = res.data.updateValueL.values[0];
                this.setValue(value);
                const scores = res.data.updateValueL.scores;
                const scoreMod = getModule(Scoring);
                scoreMod.setScores({ scores: scores });
                return res.data.updateValueL;
            }
        }
        return null;
    }

    @Action
    async moveRowL(payload: {
        table_id: number;
        old_row_id: string;
        new_row_id: string;
    }): Promise<Value[] | null> {
        const table_id = payload.table_id;
        const old_row_id = payload.old_row_id;
        const new_row_id = payload.new_row_id;

        if (table_id != null) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: moveRowL,
                variables: {
                    table_id,
                    old_row_id,
                    new_row_id,
                },
            })) as GraphQLResult<MoveRowL>;

            if (res.data?.moveRowL) {
                // this.addFactors({
                //     parentId: payload.parentId,
                //     factors: res.data.createRow.factors,
                // });
                const scores = res.data.moveRowL.scores;
                const scoreMod = getModule(Scoring);
                scoreMod.setScores({ scores: scores });
                return res.data.moveRowL.values;
            }
        }
        return null;
    }

    @Action
    async deleteValueL(payload: {
        choice_id: number;
        factor_id: number;
        row_id: string;
    }): Promise<Value | null> {
        const choice_id = payload.choice_id;
        const factor_id = payload.factor_id;
        const row_id = payload.row_id;
        if (choice_id != null && factor_id != null) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: deleteValueL,
                variables: {
                    choice_id,
                    factor_id,
                    row_id,
                },
            })) as GraphQLResult<DeleteValueL>;
            const changes = res.data?.deleteValueL;
            if (changes) {
                const values = changes.values;
                for (const value of values) {
                    this.removeValue(value);
                }
                const scores = changes.scores;
                const scoreMod = getModule(Scoring);
                scoreMod.setScores({ scores: scores });
                return changes.values[0];
            }
        }
        return null;
    }

    @Action
    async selectActiveChoice(id: number | null): Promise<void> {
        await this.selectChoice(id);
        if (id != null) {
            await this.getChoiceValues({ choice_id: id, root_only: true });
        }
    }

    @Action
    async addValues(values: Value[]): Promise<void> {
        if (values == null) {
            return;
        }
        values.forEach((value: Value) => {
            if (value) this.setValue(value);
        });
    }

    @Action
    async removeValues(values: Value[]): Promise<void> {
        values.forEach((value: Value) => {
            if (value) this.removeValue(value);
        });
    }

    @Action
    async createEditChoice(payload: {
        choice: Choice;
        mutation: string;
    }): Promise<void> {
        //If the subscription fired choice is the same as the choice on local user - it's already been handled by above functions
        if (payload.choice.id == this.createdDeletedChoiceId) {
            this.setcreatedDeletedChoiceId(null);
            return;
        }
        if (payload.mutation.includes("create")) {
            this.setChoice(payload.choice);
            if (
                this.createdDeletedChoiceId != null &&
                this.createdDeletedChoiceId === payload.choice.id
            ) {
                this.selectChoice(this.createdDeletedChoiceId);
                this.setcreatedDeletedChoiceId(null);
            }
        } else if (payload.mutation.includes("update")) {
            this.setChoice(payload.choice);
        }
    }

    @Action
    async deleteLocalChoices(choices: Choice[]): Promise<void> {
        if (choices == null || choices.length <= 0) {
            return;
        }
        //If the subscription fired choice is the same as the choice on local user - it's already been handled by above functions
        if (choices[0].id == this.createdDeletedChoiceId) {
            this.setcreatedDeletedChoiceId(null);
            return;
        }
        choices.forEach((choice: Choice) => {
            this.removeChoice(choice);
            if (choice.id == this.selectedChoiceId) {
                this.selectChoice(null);
            }
        });
    }

    @Action
    async setCommentNum(comment: Comment): Promise<void> {
        if (this.choiceValues.hasOwnProperty(comment.choice_id)) {
            if (
                this.choiceValues[comment.choice_id].hasOwnProperty(
                    comment.factor_id
                )
            ) {
                if (comment.mutation === "deleteComment") {
                    this.choiceValues[comment.choice_id][comment.factor_id]
                        .comments--;
                } else if (
                    comment.mutation === "createReply" ||
                    comment.mutation === "createComment"
                ) {
                    this.choiceValues[comment.choice_id][comment.factor_id]
                        .comments++;
                }
            }
        }
    }
}
