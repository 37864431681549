
import VpDialog from "@/components/ui/VpDialog.vue";
import PdfPreview from "@/components/common/media/preview/PdfPreview.vue";
import WordPreview from "@/components/common/media/preview/WordPreview.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { FactorOptions } from "@/graphql/API";

@Component({
    name: "DocumentPreview",
    components: {
        VpDialog,
        PdfPreview,
        WordPreview,
    },
})
export default class DocumentPreview extends Vue {
    @Prop()
    path!: string;

    @Prop({ type: String, default: "File" })
    name!: string;

    @Prop({ type: Boolean, default: false })
    isFactorTable!: boolean;

    @Prop({ type: String, default: "140px" })
    maxWidth!: string;

    @Prop({ type: String, default: "140px" })
    maxHeight!: string;

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    private previewOpen = false;

    // Computed property to check if the path is a URL for a PDF
    get isPdfUrl(): boolean {
        return (
            typeof this.path === "string" &&
            this.path.toLowerCase().endsWith(".pdf")
        );
    }
}
