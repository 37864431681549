import { Component, Vue, Prop, Watch, PropSync } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import Choices from "@/store/modules/Choices";
import TabComments from "@/components/mixins/TabComments";
import {
    Choice
} from "@/graphql/API";

const choiceModule = getModule(Choices);

@Component
export default class CommentItems extends mixins(TabComments) {
    choiceIdList: number[] = [];

    /* List of choices/viewpoints that are active in tab */
    selectedChoices: number[] = [];

    /* 
        Properties for ordering 
        - sortDef controls if list is default sort
            or if it is the order the user can rearrange
        - sortDesc controls if default sort should be 
            alphabetical or reverse alphabetical    
    */
    choiceSortDef = true;
    choiceSortDesc = true;

    /* Returns array of choices to be displayed in the tab
    - Filtered by which ids are selected */
    get choices(): Choice[] {
        return this.choiceIdList
            .filter(
                (id) =>
                    this.selectedChoices.includes(id) &&
                    choiceModule.choiceList[id]
            )
            .map((id) => choiceModule.choiceList[id]);
    }

    /* List of Objects that are available to user to toggle on/off */
    get availableChoiceIds(): number[] {
        if (!this.embed) {
            return this.frameworkChoices;
        } else {
            return this.tabChoicesIds;
        }
    }

    /* 
        Returns array of ids for choices sorted in alphabetical order
        - choiceSortDesc controls if alphabetical or reverse alphabetical
    */
    get sortedChoiceIds(): number[] {
        if (this.availableChoiceIds.length) {
            return this.availableChoiceIds.sort((a, b) => {
                if (
                    a &&
                    b &&
                    choiceModule.choiceList[a] &&
                    choiceModule.choiceList[b]
                ) {
                    return (
                        choiceModule.choiceList[a].name.localeCompare(
                            choiceModule.choiceList[b].name,
                            undefined,
                            {
                                numeric: true,
                                sensitivity: "base",
                            }
                        ) * (this.choiceSortDesc ? 1 : -1)
                    );
                } else {
                    return 1;
                }
            });
        } else {
            return [];
        }
    }

    /* Detects Change */

    get choicesChanged(): boolean {
        if (this.tabChoicesIds.length == this.selectedChoices.length) {
            if (
                this.selectedChoices.every((val: number) =>
                    this.tabChoicesIds.includes(val)
                ) &&
                this.tabChoicesIds.every((val: number) =>
                    this.selectedChoices.includes(val)
                )
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    get autoSelectedChoiceIds(): number[] {
        if (this.isMainTool || this.showAllChoicesTab) {
            return this.frameworkChoices;
        } else {
            return this.tabChoicesIds;
        }
    }

    @Watch("autoSelectedChoiceIds", { immediate: true, deep: true })
    onAutoSelectedChoiceIdsChange(
        newVal: number[],
        oldVal: number[] = []
    ): void {
        this.addIdList(
            newVal.filter((x) => !oldVal.includes(x)),
            this.selectedChoices
        );
        this.removeIdList(
            oldVal.filter((x) => !newVal.includes(x)),
            this.selectedChoices
        );
    }

    @Watch("sortedChoiceIds", { immediate: true, deep: true })
    onSortedChoiceIdChange(newVal: number[], oldVal: number[] = []): void {
        if (this.choiceSortDef) {
            this.choiceIdList = [...this.sortedChoiceIds];
        } else {
            this.addIdList(
                newVal.filter((x) => !oldVal.includes(x)),
                this.choiceIdList
            );
            this.removeIdList(
                oldVal.filter((x) => !newVal.includes(x)),
                this.choiceIdList
            );
        }
    }

    @Watch("choiceSortDef")
    onChoiceSortAlphaChange(val: boolean): void {
        if (val) {
            this.choiceIdList = [...this.sortedChoiceIds];
        }
    }
}