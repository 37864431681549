
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Factor, Choice } from "@/graphql/API";
import Factors from "@/store/modules/Factors";
import { getModule } from "vuex-module-decorators";
import CommentRow from "@/components/comments/tab/CommentRow.vue";
import Choices from "@/store/modules/Choices";

const choicesModule = getModule(Choices);

const modelModule = getModule(Factors);

@Component({
    name: "CommentRecursive",
    components: {
        CommentRow,
    },
})
export default class CommentRecursive extends Vue {
    @Prop({ type: Number, default: -1 })
    factorRoot!: number;

    @Prop({ type: Number, default: 0 })
    depth!: number;

    @Prop({ default: () => [], type: Array })
    choices!: Choice[];

    @Prop({ default: () => [], type: Array })
    hiddenFactors!: number[];

    @Prop({ default: 200, type: Number })
    mainWidth!: number;

    @Prop({ default: 200, type: Number })
    columnWidth!: number;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    borderX!: boolean;

    @Prop({ default: true, type: Boolean })
    borderY!: boolean;

    @Prop({ default: true, type: Boolean })
    showTotal!: boolean;

    @Prop({ default: false, type: Boolean })
    fullColumn!: boolean;

    @Prop({ default: () => [], type: Array })
    lines!: number[];

    private open: { [id: number]: number[] } = {};

    get currentFactorTree(): Factor[] {
        if (modelModule.factorTree[this.factorRoot]) {
            return modelModule.factorTree[this.factorRoot].filter((factor) => {
                return !this.hiddenFactors.includes(factor.id);
            });
        } else {
            return [];
        }
    }

    private async loadChoiceValues(choices: Choice[]): Promise<void> {
        if (choices.length) {
            await Promise.all(
                choices.map(async (choice) => {
                    const res = await choicesModule.getChoiceValues({
                        choice_id: choice.id,
                        root_only: this.factorRoot == -1 ? true : undefined,
                        parent_id:
                            this.factorRoot != -1 ? this.factorRoot : undefined,
                    });
                })
            );
        }
    }

    mounted(): void {
        this.onFactorRootChange();
        this.onChoiceChange(this.choices, []);
        if (this.depth == 0) {
            Vue.set(this.open, 0, [0]);
        }
    }

    @Watch("factorRoot")
    private async onFactorRootChange(): Promise<void> {
        if (this.factorRoot != -1 && !modelModule.factorTree[this.factorRoot]) {
            await modelModule.fetchChildFactors(this.factorRoot);
        }
    }

    @Watch("choices", { immediate: true, deep: true })
    async onChoiceChange(
        newVal: Choice[],
        oldVal: Choice[] = []
    ): Promise<void> {
        if (newVal && newVal.length) {
            this.loadChoiceValues(newVal.filter((x) => !oldVal.includes(x)));
        }
    }
}
