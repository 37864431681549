import {
    Comment,
    CommentCreateInput,
    CommentUpdateInput,
    ReplyCreateInput,
    Thread,
} from "@/graphql/API";
import {
    CreateComment,
    DeleteComment,
    GetComments,
    UpdateComment,
    CreateReply,
    GetReplies
} from "@/graphql/custom";
import { GRAPHQL_API } from "@/graphql/GraphqlAPI";
import {
    createComment,
    deleteComment,
    updateComment,
    createReply,
} from "@/graphql/mutations";
import { getComments, getReplies } from "@/graphql/queries";
import GraphQLAPI, { GraphQLResult } from "@aws-amplify/api-graphql";
import Vue from "vue";
import {
    Action,
    config,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "..";
import Decisions from "./Decisions";

config.rawError = true;

const name = "comments";
if (module.hot) {
    if (store.hasModule(name)) {
        store.unregisterModule(name);
    }
}

@Module({ dynamic: true, store: store, name: name, namespaced: true })
export default class Comments extends VuexModule {
    keyedThreads: { [Choice_FactorId: string]: Thread[] } = {};
    threadDict: { [thread_id: string]: Thread } = {};
    currentThreadId: number | null = null;
    otherComments: { [thread_id: number]: { [parentId: number]: Comment[] } } = {};
    keyedComments: { [Choice_FactorId: string]: Comment[] } = {};

    /* Refactor */
    keyedList: { [Choice_FactorId: string]: Comment[] } = {};
    keyedReplies: { [Comment_id: number]: Comment[] } = {};
    commentMap: { [Comment_id: number]: Comment } = {};

    currentCell: {
        factor_id: number | null;
        choice_id: number | null;
    } = {
        factor_id: null,
        choice_id: null,
    };

    commentIndex: {
        [Choice_FactorId: string]: {
            [Comment_id: number]: {
                comment: Comment;
                children: {
                    [reply_id: number]: Comment;
                };
            };
        };
    } = {};

    @Mutation
    clear(): void {
        this.keyedThreads = {};
        this.threadDict = {};
        this.keyedComments = {};
        /* Refactor */
        this.keyedList = {};
        this.keyedReplies = {};
        this.commentMap = {};
        this.commentIndex = {};
        this.currentCell = {
            factor_id: null,
            choice_id: null,
        };
    }

    @Mutation
    setCurrentCell(payload: { factor_id: number; choice_id: number }): void {
        Vue.set(this.currentCell, "factor_id", payload.factor_id);
        Vue.set(this.currentCell, "choice_id", payload.choice_id);
    }

    @Mutation
    clearCurrentCell(): void {
        Vue.set(this.currentCell, "factor_id", null);
        Vue.set(this.currentCell, "choice_id", null);
    }

    @Mutation
    setComments(payload: {
        factor_id: number;
        choice_id: number;
        comments: Comment[];
    }): void {
        Vue.set(
            this.keyedList,
            `${payload.choice_id}-${payload.factor_id}`,
            payload.comments
        );
    }

    @Mutation
    setComment(comment: Comment): void {
        const index = `${comment.choice_id}-${comment.factor_id}`;
        if (!this.keyedList.hasOwnProperty(index)) {
            Vue.set(this.keyedList, index, [comment]);
        } else {
            const findIndex = this.keyedList[index].findIndex(
                (item) => item.id === comment.id
            );
            if (findIndex >= 0) {
                this.keyedList[index].splice(findIndex, 1, comment);
            } else {
                this.keyedList[index].push(comment);
            }
        }
    }

    @Mutation
    removeComment(comment: Comment): void {
        const index = `${comment.choice_id}-${comment.factor_id}`;
        const findIndex = this.keyedList[index].findIndex(
            (item) => item.id === comment.id
        );
        if (findIndex >= 0) {
            this.keyedList[index].splice(findIndex, 1);
        }
    }

    /* Mutations: Replies */
    @Mutation
    setReply(comment: Comment): void {
        if (!this.keyedReplies.hasOwnProperty(comment.reply_to)) {
            Vue.set(this.keyedReplies, comment.reply_to, [comment]);
        } else {
            const findIndex = this.keyedReplies[comment.reply_to].findIndex(
                (item) => item.id === comment.id
            );
            if (findIndex >= 0) {
                this.keyedReplies[comment.reply_to].splice(
                    findIndex,
                    1,
                    comment
                );
            } else {
                this.keyedReplies[comment.reply_to].push(comment);
            }
        }
    }

    @Mutation
    removeReply(comment: Comment): void {
        const findIndex = this.keyedReplies[comment.reply_to].findIndex(
            (item) => item.id === comment.id
        );
        if (findIndex >= 0) {
            this.keyedReplies[comment.reply_to].splice(findIndex, 1);
        }
    }

    @Mutation
    setReplies(payload: { parent_id: number; comments: Comment[] }): void {
        Vue.set(this.keyedReplies, payload.parent_id, payload.comments);
    }

    @Action
    async setCurrCell(payload: {
        choice_id: number;
        factor_id: number;
    }): Promise<void> {
        this.setCurrentCell(payload);
    }

    @Action
    async clearCurrCell(): Promise<void> {
        this.clearCurrentCell();
    }

    @Action
    async createComment(input: CommentCreateInput): Promise<Comment | null> {
        const commentRes = (await GRAPHQL_API.graphqlMutationRequest({
            query: createComment,
            variables: {
                input,
            },
        })) as GraphQLResult<CreateComment>;
        const comment = commentRes.data?.createComment;
        if (comment) {
            this.setComment(comment);
            return comment;
        } else {
            return null;
        }
    }

    @Action
    async fetchComments(payload: {
        choice_id: number;
        factor_id: number;
    }): Promise<Comment[]> {
        const commentRes = (await GRAPHQL_API.graphqlQueryRequest({
            query: getComments,
            variables: {
                ...payload,
            },
        })) as GraphQLResult<GetComments>;
        const comments = commentRes.data?.getComments;
        if (comments) {
            this.setComments({
                factor_id: payload.factor_id,
                choice_id: payload.choice_id,
                comments: comments.filter((comment) => !comment.reply_to),
            });
            comments
                .filter((comment) => comment.reply_to)
                .map((reply) => {
                    this.setReply(reply);
                });
            return comments;
        } else {
            this.setComments({
                factor_id: payload.factor_id,
                choice_id: payload.choice_id,
                comments: [],
            });
            return [];
        }
    }

    @Action
    async updateComment(input: CommentUpdateInput): Promise<Comment | null> {
        const commentRes = (await GRAPHQL_API.graphqlQueryRequest({
            query: updateComment,
            variables: {
                input,
            },
        })) as GraphQLResult<UpdateComment>;
        const comment = commentRes.data?.updateComment;
        if (comment) {
            if (comment.reply_to) {
                this.setReply(comment);
            } else {
                this.setComment(comment);
            }
            return comment;
        } else {
            return null;
        }
    }

    @Action
    async deleteComment(id: number): Promise<Comment | null> {
        const commentRes = (await GRAPHQL_API.graphqlQueryRequest({
            query: deleteComment,
            variables: {
                id,
            },
        })) as GraphQLResult<DeleteComment>;
        const comment = commentRes.data?.deleteComment;
        if (comment) {
            if (comment.reply_to) {
                this.removeReply(comment);
            } else {
                this.removeComment(comment);
            }
            return comment;
        } else {
            return null;
        }
    }

    /* Actions: Replies */
    @Action
    async createReply(input: ReplyCreateInput): Promise<Comment | null> {
        const commentRes = (await GRAPHQL_API.graphqlMutationRequest({
            query: createReply,
            variables: {
                input,
            },
        })) as GraphQLResult<CreateReply>;
        const comment = commentRes.data?.createReply;
        if (comment) {
            this.setReply(comment);
            return comment;
        } else {
            return null;
        }
    }

    @Action
    async fetchReplies(comment_id: number): Promise<Comment[] | null> {
        const commentRes = (await GRAPHQL_API.graphqlQueryRequest({
            query: getReplies,
            variables: {
                comment_id,
            },
        })) as GraphQLResult<GetReplies>;
        const comments = commentRes.data?.getReplies;
        if (comments) {
            this.setReplies({
                parent_id: comment_id,
                comments: comments,
            });
            return comments;
        } else {
            this.setReplies({
                parent_id: comment_id,
                comments: [],
            });
            return [];
        }
    }

    @Action
    async subscribeComment(comment: Comment): Promise<void> {
        if (
            comment.choice_id == this.currentCell.choice_id &&
            comment.factor_id == this.currentCell.factor_id
        ) {
            /* Comment is in current Sidebar */
            if (comment.mutation == "deleteComment") {
                if (comment.reply_to) {
                    this.removeReply(comment);
                } else {
                    this.removeComment(comment);
                }
            } else {
                if (comment.reply_to) {
                    this.setReply(comment);
                } else {
                    this.setComment(comment);
                }
            }
        }
    }
}
