
import { Component, Watch, Prop, PropSync, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Choice,
    Viewpoint,
    Locks,
    AppOptions,
    ViewpointItem,
    ChoiceItem,
} from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
import Choices from "@/store/modules/Choices";
import draggable from "vuedraggable";
import CommentChoiceToggle from "./CommentChoiceToggle.vue";

const decisionsModule = getModule(Decisions);
const choiceModule = getModule(Choices);

@Component({
    components: {
        draggable,
        CommentChoiceToggle,
    },
})
export default class CommentChoices extends Vue {
    @PropSync("ids")
    syncedIds!: number[];

    @PropSync("choiceList")
    syncedChoiceList!: number[];

    private drag = false;

    get choicesLabel(): string {
        return decisionsModule.choiceLabelPlural;
    }

    get choiceLabel(): string {
        return decisionsModule.choiceLabelSingular;
    }

    get choices(): { [id: number]: Choice } {
        return choiceModule.choiceList;
    }

    private toggleItem(id: number): void {
        if (this.syncedChoiceList.includes(id)) {
            this.syncedChoiceList.splice(this.syncedChoiceList.indexOf(id), 1);
        } else {
            this.syncedChoiceList.push(id);
        }
    }

    private toggleAll(val: boolean): void {
        if (val) {
            this.syncedChoiceList = [];
        } else {
            this.syncedChoiceList = [...this.syncedIds];
        }
    }
}
