
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
    Factor,
    Choice,
    Value,
} from "@/graphql/API";
import CompareRow from "@/components/compare/ui/CompareRow.vue";
import CompareColumnMain from "@/components/compare/ui/CompareColumnMain.vue";
import CompareColumn from "@/components/compare/ui/CompareColumn.vue";
import CommentCell from "@/components/comments/tab/CommentCell.vue";

@Component({
    components: {
        CompareRow,
        CompareColumnMain,
        CompareColumn,
        CommentCell,
    },
})
export default class CommentRow extends Vue {
    @Prop({ default: () => [], type: Array })
    choices!: Choice[];

    @Prop({ required: true })
    factor!: Factor;

    @Prop({ type: Number, default: 0 })
    depth!: number;

    @Prop({ type: Number, default: 0 })
    rowIndex!: number;

    @Prop({ default: 200, type: Number })
    mainWidth!: number;

    @Prop({ default: 200, type: Number })
    columnWidth!: number;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    borderX!: boolean;

    @Prop({ default: true, type: Boolean })
    borderY!: boolean;

    @Prop({ default: true, type: Boolean })
    showTotal!: boolean;

    @Prop({ default: false, type: Boolean })
    fullColumn!: boolean;

    @Prop({ default: false, type: Boolean })
    highlight!: boolean;

    @Prop({ default: false, type: Boolean })
    group!: boolean;

    @Prop({ default: () => [], type: Array })
    lines!: number[];

    @Prop({ default: false, type: Boolean })
    expand!: boolean;

    @Prop({ default: false, type: Boolean })
    last!: boolean;

    get customClass(): string {
        return this.expand
            ? ` vp-comment-row-highlight vp-comment-row-highlight-${this.depth}`
            : " ";
    }
}
